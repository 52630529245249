//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { imgChange } from "@/commons";
export default {
  components: {
    XModal: () => import("@/components/XModal"),
  },
  props: {
    teamValue: {
      type: Number,
      default: null,
    },
    selMem: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      pageData: {
        pageIndex: 1,
        pageSize: 20,
        totalNum: 0,
      }, //分页
      loading: false,
      tableData: [], //表格数据
      searchVal: null,
      cellStyle: {
        textAlign: "center",
      },
    };
  },
  methods: {
    imgChange,
    /**
     * 关键词搜索
     */
    handleSearch() {
      this.pageData.pageIndex = 1;
      this.getAllData();
    },
    /**
     * 选择任务
     */
    handleSel(sel) {
      this.$emit("getTask", sel);
      this.$modal.hide("tasklist");
    },
    /**
     * 打开弹窗回调
     */
    opened() {
      this.$nextTick(() => {
        this.getAllData();
      });
    },
    /**
     * 获取全部任务数据
     */
    getAllData() {
      const data = {
        teamId: this.teamValue,
        pageIndex: this.pageData.pageIndex,
        pageSize: this.pageData.pageSize,
        name: this.searchVal,
      };
      this.loading = true;
      this.$http
        .get("/Task/GetMemberTaskSeleced.ashx", { params: data })
        .then((resp) => {
          if (resp.res == 0) {
            this.tableData = resp.data.Data;
            this.pageData.totalNum = resp.data.TotalCount;
          }
        })
        .finally(() => (this.loading = false));
    },
    /**
     * 每页条数改变
     */
    handleSizeChange(val) {
      this.pageData.pageSize = val;
      this.pageData.pageIndex = 1;
      this.getAllData();
    },
    /**
     * 页数改变
     */
    handleCurrentChange(val) {
      this.pageData.pageIndex = val;
      this.getAllData();
    },

    attenceHeaderStyle() {
      return {
        color: "#909399",
      };
    },
  },
};
